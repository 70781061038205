import React, { useContext, useState } from "react";
import { Box, Button, Card, IconButton, Snackbar, Stack, Typography as Label } from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import { Formik } from 'formik';
import * as yup from 'yup';
import { AuthContext } from "../Context/AuthContext";
import MenuStrip from '../Components/MenuStrip';
import Footer from '../Components/Footer';
import { TextArea, TextBox } from "../Components/TextBox";
import { Colours } from '../Resources/Values/Styles';
import { Contacts as Cont } from "../Resources/Values/Data";
import MailContact from "../Models/MailContactModel";
import { MailServer } from "../Controllers/DB";

export default function ContactsPage() {
    const { isMobile } = useContext(AuthContext);

    const validationSchema = yup.object().shape({
        Name: yup.string().required(),
        CO: yup.string(),
        Email: yup.string().required('Please Provide Your Contacts'),
        MSG: yup.string().required('Message Can Not Go Unheard'),
    })

    const [SnackOpen, SetSnackOpen] = useState(false);

    const SnackbarHandleClick = () => {
        SetSnackOpen(true);
    };

    const SnackbarHandleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        SetSnackOpen(false);
    };

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh'>
            <MenuStrip />
            <Snackbar
                open={SnackOpen}
                autoHideDuration={3000}
                onClose={SnackbarHandleClose}
                message="Requast Sent"
                action={<React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={SnackbarHandleClose}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </React.Fragment>}
            />
            <Box sx={{ backgroundColor: Colours.Secondary, paddingY: isMobile ? 5 : 20, marginX: isMobile ? 2 : 5, color: Colours.Primary }}
                display={"flex"} flexDirection={"row"} justifyContent={'center'} alignItems={'center'}>
                <Label variant='h2' textAlign={'center'}>Contact LNI.Tek</Label>
            </Box>
            <Box display={"flex"} flexDirection={isMobile ? 'column' : "row"} justifyContent={'center'} alignItems={'start'}>
                <Box border={isMobile ? 'none' : '1px solid gray'} width={isMobile ? '80%' : 750} padding={isMobile ? 0 : 5} marginTop={5} marginLeft={5}>
                    <Formik
                        initialValues={{
                            Name: '',
                            CO: '',
                            Email: '',
                            MSG: '',
                        }}

                        validationSchema={validationSchema}

                        onSubmit={(Values) => {
                            SnackbarHandleClick();
                            MailServer(new MailContact(`${Values.Name} @ ${Values.CO}`, Values.Email, Values.MSG, "", "", new Date()));
                        }} >
                        {({ handleSubmit }) => {
                            return (
                                <Box>
                                    <Label>Name</Label>
                                    <TextBox field={{ name: 'Name' }} fullWidth
                                        placeholder="First Name Last Name" />
                                    <Label sx={{ marginTop: 2.5 }}>Company Name</Label>
                                    <TextBox field={{ name: 'CO' }} fullWidth
                                        placeholder="LNI.Tek" />
                                    <Label sx={{ marginTop: 2.5 }}>Email</Label>
                                    <TextBox field={{ name: 'Email' }} fullWidth
                                        placeholder="Company@Email.com" />
                                    <Label sx={{ marginTop: 2.5 }}>Message</Label>
                                    <TextArea field={{ name: 'MSG' }} minRows={10}
                                        placeholder="Hi There, I'm Interested In What You Have To Say" />
                                    <Button sx={{ marginTop: '10px', borderRadius: 2, border: '1px solid gray' }}
                                        color='inherit' onClick={() => handleSubmit()} fullWidth endIcon={<Send />}>
                                        Submit
                                    </Button>
                                </Box>
                            )
                        }}
                    </Formik>
                </Box>
                <Stack sx={{ margin: 10, marginTop: 5 }}>
                    <Card sx={{ padding: 2 }}>
                        <Label variant="h6">Working Hours</Label>
                        <Label sx={{ opacity: .5 }}>9AM - 4PM</Label>
                        <Label sx={{ opacity: .5 }}>Mon - Fri</Label>
                    </Card>
                    <Card sx={{ padding: 2, marginTop: 2 }}>
                        <Label variant="h6">Contact Info</Label>
                        {Cont.map((x, i) => <Label key={i} sx={{ opacity: .5 }}>{x}</Label>)}
                    </Card>
                    {/*
                    <Card sx={{ padding: 2, marginTop: 2 }}>
                        <Label variant="h6">Egbert Scherman</Label>
                        <Label sx={{ opacity: .5 }}>CEO - Lead Developer</Label>
                    </Card>*/
                    }
                </Stack>
            </Box>
            <Footer HideToTop={true} />
        </Box>
    )
}