import { addDoc, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getAuth, updateEmail, updatePassword, updateProfile } from 'firebase/auth';
import Project from "../Models/ProjectModel";
import UserDoc from "../Models/UserDocModel";
import MailContact from "../Models/MailContactModel";
import Key from "../Models/KeysModel";
import Ticket from "../Models/TicketModel";
import Provider from "../Models/ProviderModel";

const GetFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyALydDx4esk5JFNVU539mw882xlVEj_jKM",
        authDomain: "lnitek.firebaseapp.com",
        projectId: "lnitek",
        storageBucket: "lnitek.appspot.com",
        messagingSenderId: "959002996047",
        appId: "1:959002996047:web:5357bf1b16fd6e8539693a",
        measurementId: "G-92J4EY3BSG"
    };

    return initializeApp(firebaseConfig);
};
export const FB = GetFirebase();

export async function GetUser() {
    const User = getAuth().currentUser;
    if (!User) return;
    const db = getFirestore(FB);
    return (await getDoc(doc(db, "Users", User.uid))).data() as UserDoc;
}

export async function SaveNewUser(CO: string) {
    const User = getAuth().currentUser;
    if (!User) return;
    const db = getFirestore(FB);
    await setDoc(doc(db, "Users", User.uid), { CO: CO, Projects: [] });
}

export async function ChangeCO(CO: string) {
    const User = getAuth().currentUser;
    if (!User) return;
    const db = getFirestore(FB);
    await setDoc(doc(db, "Users", User.uid), { CO: CO });
}

export async function ChangeName(Name: string) {
    const User = getAuth().currentUser;
    if (!User) return;
    await updateProfile(User, { displayName: Name });
}

export async function ChangeEmail(Email: string) {
    const User = getAuth().currentUser;
    if (!User) return;
    await updateEmail(User, Email);
}

export async function ChangePass(Pass: string) {
    const User = getAuth().currentUser;
    if (!User) return;
    await updatePassword(User, Pass);
}

export async function GetProject(ID: string) {
    const db = getFirestore(FB);

    let Proj = await getDoc(doc(db, "Projects", ID)).then(x => x.data() as Project, x => null);
    if (Proj) {
        Proj.ID = ID;
        Proj.Providers = (await getDocs(query(collection(db, "Projects/" + ID + "/Providers")))).docs.map(x => Provider.Gen(x.data() as Provider))
            .sort((a, b) => a.Orderby - b.Orderby);
        return Project.Gen(Proj);
    }
}

export async function GetPublicProjects() {
    const db = getFirestore(FB);
    return await Promise.all((await getDocs(query(collection(db, "Projects"), where("Public", '==', true)))).docs
        .map(async doc => {
            let Proj = Project.Gen(doc.data() as Project);
            Proj.ID = doc.id;
            Proj.Providers = (await getDocs(query(collection(db, "Projects/" + doc.id + "/Providers")))).docs.map(x => Provider.Gen(x.data() as Provider))
            .sort((a, b) => a.Orderby - b.Orderby);
            return Proj;
        }));
}

export async function MailServer(MailContact: MailContact) {
    const DB = getFirestore(FB);
    await addDoc(collection(DB, "MailList"), {
        Name: MailContact.Name,
        Email: MailContact.Email,
        Message: MailContact.Message,
        ServiceName: MailContact.ServiceName,
        ServiceTag: MailContact.ServiceTag,
    });
}

export async function GetTicets() {
    const DB = getFirestore(FB);
    let Tickets = [] as Ticket[];
    (await getDocs(query(collection(DB, "Tickets"), where("UserID", '==', getAuth().currentUser?.uid)))).forEach(doc => {
        let Ticket = doc.data() as Ticket;
        Tickets.push(Ticket)
    });
    return Tickets;
}

export async function GetTicet(ID: string) {
    const db = getFirestore(FB);

    let Tickets = await getDoc(doc(db, "Tickets", ID)).then(x => x.data() as Ticket, x => null);
    return Tickets;
}

export async function ActivateKey(Code: string) {
    const DB = getFirestore(FB);
    var Key = await getDoc(doc(DB, 'Keys', Code)).then(x => x.data() as Key, x => null);
    if (!Key || !Key.ProjectID) return 'No Key Maching That Code.';
    const User = getAuth().currentUser;
    const UDoc = await GetUser();
    if (!User || !UDoc) return 'No User Loged In.';
    if (UDoc.Projects.includes(Key.ProjectID)) return 'Alrady Have That Project In Libery.';
    await updateDoc(doc(DB, "Users", User.uid), { Projects: arrayUnion(Key.ProjectID) });
    await updateDoc(doc(DB, "Keys", Code), { UserID: User.uid });
    return true;
}