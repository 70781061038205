import { TextField, Box, TextFieldProps } from '@mui/material';
import {TextareaAutosize as BaseTextareaAutosize , TextareaAutosizeProps} from '@mui/base'
import { styled } from '@mui/system';
import { useField } from 'formik';

type TextBoxProp = TextFieldProps & { field: { name: string } }
type TextAreaProp = TextareaAutosizeProps & { field: { name: string } }

export function TextBox(props: TextBoxProp) {
    const [field, meta] = useField(props.field);

    return (
        <Box display={'flex'}>
            <TextField {...props} {...field}
                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error ? meta.error : ''} />
        </Box>
    )
}

export function TextArea(props: TextAreaProp) {
    const [field, meta] = useField(props.field);

    const TextareaAutosize = styled(BaseTextareaAutosize)(
        ({ theme }) => `
        box-sizing: border-box;
        width: 320px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        width: 100%;
      `,
      );

    return (
        <Box display={'flex'}>
            <TextareaAutosize {...props} {...field}/>
        </Box>
    )
}