import { useContext } from 'react';
import { useQuery } from "react-query";
import { Box, Typography as Label, Button, Card, CardContent, CardActions, Grid2 as Grid, Paper } from "@mui/material";
import { AuthContext } from '../Context/AuthContext';
import MenuStrip from '../Components/MenuStrip';
import Footer from '../Components/Footer';
import Loader from '../Components/Loader';
import { Colours } from '../Resources/Values/Styles';
import { GetPublicProjects } from '../Controllers/DB';

export default function MainPage() {
    const { isMobile } = useContext(AuthContext);

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh'>
            <MenuStrip />
            {isMobile ?
                <Box sx={{ backgroundColor: Colours.Secondary, paddingY: 5, marginX: 2, paddingLeft: 2 }} color={Colours.Primary}>
                    <Label sx={{ opacity: .8 }} fontSize={20}>LNI.Tek provides outsourced</Label>
                    <Label variant='h2'>Custom Software</Label>
                    <Label sx={{ opacity: .8 }} fontSize={15}>for small businesses or personal use</Label>
                    <Button sx={{ border: '1px solid gray', marginTop: 5 }} color='inherit' href='/Contact'>
                        Get In Touch
                    </Button>
                </Box> :
                <Box sx={{ backgroundColor: Colours.Secondary, paddingTop: 20, paddingBottom: 20, margin: 5, marginTop: 0, paddingLeft: 20 }}
                    padding={5} color={Colours.Primary}
                    display={"flex"} flexDirection="column" justifyContent={'center'} alignItems={'start'}>
                    <Label sx={{ opacity: .8 }} fontSize={25}>LNI.Tek Provides Outsourced</Label>
                    <Label variant='h2'>Custom Software</Label>
                    <Label sx={{ opacity: .8 }} fontSize={20}>For Small Businesses Or Personal Use</Label>
                    <Button sx={{ border: '1px solid gray', marginTop: 5 }} color='inherit' href='/Contact'>
                        Get In Touch
                    </Button>
                </Box>
            }
            <Box sx={{ border: '1px solid lightgray', margin: 5 }} />
            <Label sx={{ opacity: .8, marginLeft: 5, marginBottom: -5 }}>About</Label>
            <Grid container>
                <Grid size={{ xs: 12, sm: 6 }} sx={{ padding: 5 }} marginBottom={'auto'}>
                    <Label variant='h4'>What is LNI.Tek</Label>
                    <Label>
                        We develop custom software solutions to improve and simplify your workflow.
                        Our software uses modern technologies to develop professional software.
                        Working businesses need working software! We Supply fast maintenance, fixes and full customisation.
                    </Label>
                    <Label marginY={2}>
                        We develop desktop / laptop programs for office and home use, mobile apps for portability and server solutions.
                    </Label>
                    <Label>LNI.Tek - Where Fiction Becomes Reality.</Label>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }} sx={{ padding: 5 }} marginBottom={'auto'}>
                    <Label variant='h4'>What is custom software</Label>
                    <Label>
                        Custom software is as easy as it sounds. We develop software from the ground up then we modify,
                        adjust and transform the software to fit your work style.
                        As custom software can be molded we adapt to make learning the new software a breeze.
                        No training or courses are required.
                    </Label>
                </Grid>
            </Grid>
            <Box display={"flex"} flexDirection={isMobile ? 'column' : "row"} justifyContent={'center'} alignItems={'start'}>

                <Button sx={{
                    border: '1px solid gray', width: 125, justifyContent: 'center',
                    position: isMobile ? 'static' : 'absolute', alignSelf: isMobile ? 'center' : 'flex-end'
                }}
                    color='inherit' href='/About'>
                    Learn More
                </Button>
            </Box>
            <Box sx={{ border: '1px solid lightgray', margin: 5 }} />
            <Box display={"flex"} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
                <Label variant='h4'>Active Projects</Label>
                <OpenProjects />
                <Button sx={{ border: '1px solid gray', marginTop: 5 }} color='inherit' href='/Contact'>
                    Contact →
                </Button>
            </Box>
            <Footer />
        </Box>
    )
}

function OpenProjects() {
    const { isMobile } = useContext(AuthContext);
    const { data: PublicProject, status } = useQuery("MainPublicProject", FechPubProj);

    async function FechPubProj() {
        let Arr = await GetPublicProjects();
        if (!Arr) return;
        return Arr.slice(0, isMobile ? 2 : 3);
    }

    if (status === "loading") {
        return <Box display={'flex'} justifyContent='center' alignItems={'center'} height={200}>
            <Loader />
        </Box>
    }

    if (status === "error") {
        return <div>Error...</div>
    }

    return (
        <Grid container spacing={isMobile ? .5 : 2} padding={2} paddingX={isMobile ? 2 : 20} wrap='nowrap'>
            {PublicProject?.sort((x) => x.LastUpdated?.seconds ?? 0)?.slice(0, isMobile ? 2 : 3)?.map((x, i) =>
                <Grid key={i} size={{ xs: 6, sm: isMobile ? 6 : 4 }}>
                    <Card sx={{ height: 200, display: 'flex', flexDirection: 'column' }} >
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Label variant='h4'> {x.Name} </Label>
                            <Label sx={{ fontSize: 15, opacity: .7 }}> {x.ShortDesc(isMobile ? 75 : 150)} </Label>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color='inherit' href={'Projects/' + x.ID}>Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>)}
        </Grid>
    )
}