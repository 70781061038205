import Services from "../../Models/ServicesModel";

export const StorageVar = {
    PublicProjects: 'PubProj',
    NewsFeeds: 'NewsFeeds',
};

export const Contacts = [
    "Email : egbert@lnitek.com",
    "Phone : +27 (69) 237 0057",
    "- WhatsApp friendly",
]

export const PublisherType = {
    None: 0,
    Self: 1,
    Github: 2,
    GooglePlay: 3,
    Microsoft: 4,
    Apple: 5,
    Harmony: 6,
    Linux: 7,
    Web: 8,
    Other: 9,
}

export const TicketEnum = {
    New: 0,
    Open: 1,
    Close: 2,
}

export const FilterTags = [
    'Linux',
    'Windows',
    'Apple',
    'IOS',
    'Android',
    'Multi-Platform',
    'Web',
    'Server',
    'App',
    'Program',
    'Games',
    'Free',
]

export const AllServices = [
    //Android
    new Services('Android Software', 
        'Great use case for mobile businesses, or client application.',
        ['Product shop', "Services preview", "Music and video player", "Informasion sharing", "Management utilities"],
        [".net Maui", "uno Platform"]),
    //Windows
    new Services('Windows Software', 
        'Best option for most offices and desktop computers, or for traveling / remote work where a laptop is best suited.',
        ['Data','Users','Clients','Project or product management','Invoice creator', 'ect.'], 
        ['WPF for windows 7', "WinUI3 for windows 10/11"]),
    new Services('Linux Software', 
        'Best option for most offices and desktop computers, or for traveling / remote work where a laptop is best suited. Linux is also more lite weight as it uses less resources and can revive old computers.',
        ['Data','Users','Clients','Project or product management','Invoice creator', 'ect.'], 
        []),
    new Services('IOS Software', 
        'Coming Soon!',
        ['WIP'], 
        ['.net Maui', "uno Platform"], false),
    new Services('Apple Software', 
        'Coming Soon!',
        ['WIP'], 
        ['.net Maui', "uno Platform"], false),
    new Services('Multi-Platform Software', 
        'Multi-Platform solutions is used to create one program that can work on any of the other supported platforms. It is cheaper to maintain and can bring similarity and simplicity across all your devices.',
        ['Shop front', 'Info sharing'], 
        [ ".net Maui", "uno Platform"]),
    new Services('Website Software', 
        'Websites are great for displaying information about your business, products or services.',
        ['This website', 'Info or service provider', 'Store page'], 
        ['React JS'], false),
    new Services('Web-app Software', 
        'Web-apps are apps for your device (Windows, android), but works instead on your browser. Allowing your app more versatility on all platforms!',
        ['Word proseser', 'Content creater / viewer'], 
        [], false),
    new Services('Server Software', 
        'Server software is software designed for large scale use. We provide for both Windows and Linux platforms.',
        ['Rest API', 'Database hosting', 'Storage hosting'], 
        ['ASP.Net']),
    new Services('Server Setup', 
        'We can setup servers to support your needs or our software.',
        ['Installing the operating system', 'Installing other software / services', 'Configuring the server, internet or users'], 
        ['Windows', 'Linux'], false),
    new Services('Desktop Setup', 
        'We can setup home or work computers to your specifications.',
        ['Instaling the operating system', 'Configuring the operating system', 'Installing other software'], 
        ['Windows'], false),
]//  ', '   Coming Soon!  