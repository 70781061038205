import React from 'react';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router';
import { QueryClientProvider, QueryClient } from 'react-query';
import AuthContextProvider from './Context/AuthContext';
import PageNotFound from './Views/404 Page';
import MainPage from './Views/MainPage';
import AboutPage from './Views/AboutPage';
import ProjectsPage from './Views/ProjectsPage';
import ProjectsDisplayPage from './Views/ProjectsDisplayPage';
import PolicyDisplayPage from './Views/PolicyDisplay';
import TCDisplayPage from './Views/TCDisplay';
import BuildPage from './Views/BuildPage';
import ContactsPage from './Views/ContactsPage';
import AccountPage from './Views/AccountPage';
import LogInPage from './Views/LogInPage';
import SignUpPage from './Views/SignUpPage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/About' element={<AboutPage />} />
            <Route path='/Projects' element={<ProjectsPage />} />
            <Route path='/Projects/:id' element={<ProjectsDisplayPage />} />
            <Route path='/Projects/:id/Policy' element={<PolicyDisplayPage />} />
            <Route path='/Projects/:id/TC' element={<TCDisplayPage />} />
            {/* <Route path='/Build' element={<BuildPage />} /> */}
            <Route path='/Contact' element={<ContactsPage />} />
            <Route path='/Account' element={<AccountPage />} />
            <Route path='/Login' element={<LogInPage />} />
            <Route path='/SignUp' element={<SignUpPage />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
