import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { Box, Button, IconButton, InputAdornment, Typography as Label } from "@mui/material";
import { Formik } from 'formik';
import * as yup from 'yup';
import Loader from './Loader';
import { AuthContext } from '../Context/AuthContext';
import { ChangeCO, ChangeEmail, ChangeName, ChangePass, GetUser } from '../Controllers/DB';
import { TextBox } from "../Components/TextBox";
import { ProviderId } from 'firebase/auth';
import { Edit } from '@mui/icons-material';
import DialogBox from './DialogBox';
import { StringNotNullOrEmpty } from '../Controllers/Validater';

interface Props {
}

export default function AccountEditor(props: Props) {
    const [MSG, SetMSG] = useState('');
    const { User, handleLogin } = useContext(AuthContext);
    const { data: UserDoc, status } = useQuery("UserInfo", async () => await GetUser());
    const [DialogOpen, SetDialogOpen] = useState(false);

    const validationSchema = yup.object().shape({
        Email: yup.string().required().email(),
        Name: yup.string(),
        CO: yup.string(),
    })

    if (status === "loading") {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === "error") {
        return <div>Error...</div>
    }

    return (<Formik
        initialValues={{
            Email: User?.email ?? '',
            Name: User?.displayName ?? '',
            CO: UserDoc?.COName ?? '',
        }}

        validationSchema={validationSchema}

        onSubmit={async (Values) => {
            if (Values.Email !== User?.email) { await ChangeEmail(Values.Email); SetMSG("'Email' Is Updated"); }
            if (Values.Name !== User?.displayName) { await ChangeName(Values.Name); SetMSG("'Name' Is Updated"); }
            if (Values.CO !== UserDoc?.COName) { await ChangeCO(Values.CO); SetMSG("'Company' Is Updated"); }
        }} >

        {({ handleSubmit }) => {
            return (
                <Box display={'flex'} flexDirection='column' justifyContent='center' alignItems={'center'}>
                    <Box width={300}>
                        <TextBox sx={{ marginBottom: '15px' }} fullWidth
                            size='medium' variant='outlined' disabled={User?.providerData[0].providerId !== ProviderId.PASSWORD}
                            label="Email" field={{ name: 'Email' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleSubmit()}>
                                            <Edit />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                        <TextBox sx={{ marginBottom: '15px' }} fullWidth
                            size='medium' variant='outlined' disabled={User?.providerData[0].providerId !== ProviderId.PASSWORD}
                            label="Name" field={{ name: 'Name' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleSubmit()}>
                                            <Edit />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                        <TextBox sx={{ marginBottom: '15px' }} fullWidth
                            size='medium' variant='outlined'
                            label="Company" field={{ name: 'CO' }} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleSubmit()}>
                                            <Edit />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                        <Button sx={{ borderRadius: 1.5, border: '1px solid gray', backgroundColor: 'white' }}
                            fullWidth color='inherit' onClick={() => SetDialogOpen(true)}>
                            Change Password
                        </Button>
                        <DialogBox open={DialogOpen} onClose={async (OldPass, NewPass) => {
                            SetDialogOpen(false);
                            if (!StringNotNullOrEmpty(OldPass) || !StringNotNullOrEmpty(NewPass)) { SetMSG("Invalid Password"); return; }
                            if (!User || !User.email) { SetMSG("Could Not Load User Data"); return; }
                            SetMSG(await handleLogin(User.email, OldPass, User.providerData[0].providerId));
                            if (MSG !== "") return;
                            NewPass = NewPass.trim();
                            await ChangePass(NewPass);
                            SetMSG("'Password' Is Updated");
                        }} />
                        <Label marginTop={2} fontSize={15} textAlign='center'>{MSG}</Label>
                    </Box>
                </Box>
            )
        }}
    </Formik>
    )
}