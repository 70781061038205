import { useContext } from "react";
import { Box, Grid2 as Grid, Typography as Label, Button } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import MenuStrip from '../Components/MenuStrip';
import Footer from '../Components/Footer';
import { Colours } from '../Resources/Values/Styles';
import { AllServices } from "../Resources/Values/Data";

export default function AboutPage() {
    const { isMobile } = useContext(AuthContext);

    return (
        <Box display={'flex'} flexDirection={'column'} height={'100vh'}>
            <MenuStrip />
            <Box sx={{ backgroundColor: Colours.Secondary, paddingY: 20, marginX: isMobile ? 2 : 5, color: Colours.Primary }}
                display={"flex"} flexDirection={"row"} justifyContent={'center'} alignItems={'center'}>
                <Label variant='h2' textAlign={'center'}>
                    About LNI.Tek
                </Label>
            </Box>
            <Box display={"flex"} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
                <Label variant='h4' textAlign={'center'} marginTop={1}>LNI.Tek Is All About Customization</Label>
                {/* <Label variant='h4' textAlign={'center'} marginTop={isMobile ? 3 : 0}>Where Fiction Becomes Reality</Label> */}
            </Box>
            <Box sx={{ border: '1px solid lightgray', margin: 5 }} />
            <Box display={"flex"} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
                <Label sx={{ opacity: .5, maxWidth: 600, margin: 2 }} fontSize={20}>
                    We specialise in designing custom made software solutions for small and medium businesses. Using modern tools and technologies, ensuring your software operates seamlessly on our supported platforms.
                </Label>
                <Label sx={{ opacity: .5, maxWidth: 600, margin: 2 }} fontSize={20} visibility={"collapse"}>
                    Our services includes, and not limited to: Invoice creation, stock and user management systems, multi-media and more.
                </Label>
            </Box>
            <Box sx={{ border: '1px solid lightgray', margin: 5 }} />
            <Box display={"flex"} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
                <Label variant='h4'>Services</Label>
                <Grid container spacing={2} padding={isMobile ? 2 : 20} paddingTop={2} alignItems="start">
                    {AllServices.filter(x => x.Avalabel).map((x, I) =>
                        <Grid size={{ xs: 12, sm: 4 }}  key={I}>
                            <Box maxWidth={450}>
                                <Label variant='h5' marginBottom={1}
                                        borderBottom={1} borderColor={Colours.Secondary}>
                                    {x.Name}
                                </Label>
                                <Label sx={{ opacity: .5 }} fontSize={18}
                                        borderBottom={1} borderColor={Colours.Tertiary}>
                                    {x.Description}
                                </Label>
                                <Label sx={{ opacity: .5, marginTop: 1 }} fontSize={18}>
                                    For example : {x.Examples.join(', ')}.
                                </Label>
                                <Label sx={{ opacity: .5, marginTop: 1 }} fontSize={18}>
                                    Frameworks : {x.Frameworks.join(', ')}.
                                </Label>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Button sx={{ border: '1px solid gray', color: 'inherit' }} href='/Contact'>
                    Contact →
                </Button>
            </Box>
            <Footer />
        </Box>
    )
}