import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { Box, Paper, Stack, Tab, Tabs, Typography as Label, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Menu, MenuItem, } from "@mui/material";
import { AccountCircle, Add, BugReport, ExpandLess, ExpandMore, LabelImportant, ListAlt, Settings } from "@mui/icons-material";
import { AuthContext } from "../Context/AuthContext";
import { GetTicets, GetUser } from "../Controllers/DB";
import MenuStrip from "../Components/MenuStrip";
import SideNav from "../Components/SideNav";
import Loader from "../Components/Loader";
import MassProjectsDisplay from "../Components/MassProjectsDisplay";
import AccountEditor from "../Components/AccountEditor";
import TicketCreator from "../Components/TicketCreator";
import { Colours } from "../Resources/Values/Styles";

const NavEnum = {
    Account: 0,
    Projects: 1,
    Tickets: 2,
}

export default function AccountPage() {
    const { User, UserLoadingStatus, isMobile } = useContext(AuthContext);
    const navigator = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(NavEnum.Account);
    const [selectedTicket, setSelectedTicket] = useState(-1);
    const [TicketListOpen, setTicketListOpen] = useState(false);
    const [TicketMenuAnchor, setTicketMenuAnchor] = useState<null | HTMLElement>(null);
    const TicketMenuOpen = Boolean(TicketMenuAnchor);
    let SelTicket = -1;
  
    const ClickTicketList = () => {
      setTicketListOpen(!TicketListOpen);
    };

    const OpenTicketMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setTicketMenuAnchor(event.currentTarget);
      };
      const CloseTicketMenu = () => {
        setTicketMenuAnchor(null);
      };

    const { data: UserDoc, status } = useQuery("UserDoc", async () => {
        let UDoc = await GetUser();
        if (!UDoc) throw '';
        return UDoc;
    });
    const { data: Tick, status: statusTicket } = useQuery("OwnTickets", async () => await GetTicets(),{enabled:!!UserDoc});

    const [TabValue, SetTabValue] = useState(0);
    const [Display, SetDisplay] = useState(DisplayChange(NavEnum.Account));

    function DisplayChange(Nav: number) {
        switch (Nav) {
            case NavEnum.Account:  return (<AccountEditor />);
            case NavEnum.Projects:  return (<MassProjectsDisplay Values={UserDoc?.Projects} />)
            case NavEnum.Tickets: if(Tick) return (<TicketCreator Ticket={Tick[SelTicket]} />); break;
            default: return (<></>);
        }
    }

    const ChangeDisplay = (newValue: number) => {
        setSelectedIndex(newValue);
        SetTabValue(newValue);
        SetDisplay(DisplayChange(newValue));
        if(newValue != NavEnum.Tickets) setSelectedTicket(-1);
    };

    if (status === "loading" || statusTicket === "loading" || UserLoadingStatus === "loading") {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === "error" || statusTicket === "error" || UserLoadingStatus === "error" || !User) navigator('/');

    function SideBar() {
        function TabProps(index: number) {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        }

        if (isMobile) return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Paper sx={{
                    width: '100%', padding: 2, marginBottom: 5,
                    display: 'flex', flexDirection: 'row', rowGap: '15px'
                }} elevation={5}>
                    <Tabs value={TabValue} onChange={(e, v) => ChangeDisplay(v)} aria-label="basic tabs example"
                        textColor="inherit" variant="scrollable" scrollButtons="auto">
                        <Tab label="Account" {...TabProps(0)} onClick={() => ChangeDisplay(NavEnum.Account)} />
                        <Tab label="Projects" {...TabProps(1)} onClick={() => ChangeDisplay(NavEnum.Projects)} />
                        {/* <Tab label='Tickets' {...TabProps(2)} aria-controls={TicketMenuOpen ? 'basic-menu' : undefined}
                            aria-haspopup="true" aria-expanded={TicketMenuOpen ? 'true' : undefined}
                            onClick={OpenTicketMenu}/> */}
                    </Tabs>
                </Paper>
{/*                 
                <Menu id="ticket-menu" anchorEl={TicketMenuAnchor} open={TicketMenuOpen}
                        onClose={CloseTicketMenu} MenuListProps={{'aria-labelledby': 'basic-button'}}>
                        <MenuItem onClick={CloseTicketMenu}>Profile</MenuItem>
                        <MenuItem key={-1} sx={{ pl: 4 }}
                                onClick={() =>{ SelTicket = -1; setSelectedTicket(-1); CloseTicketMenu(); ChangeDisplay(NavEnum.Tickets); }}>
                                New Ticket
                        </MenuItem>
                        {Tick?.map((x,i) => 
                            <MenuItem key={i} sx={{ pl: 4 }} selected={selectedTicket === i} 
                                            onClick={() =>{ SelTicket = i; setSelectedTicket(i); CloseTicketMenu(); ChangeDisplay(NavEnum.Tickets); }}>
                                {x.Subject}
                            </MenuItem>
                        )}
                </Menu> */}
            </Box >
        );else return (
            <List sx={{ width: '15%', height: '100%', bgcolor: 'background.paper'}}
                component="nav" aria-labelledby="nested-list-subheader">
                <ListItemButton selected={selectedIndex === NavEnum.Account} onClick={() => ChangeDisplay(NavEnum.Account)}>
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary="Account" />
                </ListItemButton>
                <ListItemButton selected={selectedIndex === NavEnum.Projects} onClick={() => ChangeDisplay(NavEnum.Projects)}>
                  <ListItemIcon>
                    <ListAlt />
                  </ListItemIcon>
                  <ListItemText primary="Projects" />
                </ListItemButton>
                {/* <ListItemButton onClick={ClickTicketList}>
                  <ListItemIcon>
                    <BugReport />
                  </ListItemIcon>
                  <ListItemText primary="Tickets" />
                  {TicketListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={TicketListOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                        <ListItemButton key={-1} sx={{ pl: 4 }}
                                        onClick={() =>{ SelTicket = -1; setSelectedTicket(-1); ChangeDisplay(NavEnum.Tickets); }}>
                        <ListItemIcon>
                            <Add/>
                        </ListItemIcon>
                        <ListItemText primary={'New Ticket'}/>
                        </ListItemButton>
                    {Tick?.map((x,i) => 
                        <ListItemButton key={i} sx={{ pl: 4 }} selected={selectedTicket === i} 
                                        onClick={() =>{ SelTicket = i; setSelectedTicket(i); ChangeDisplay(NavEnum.Tickets); }}>
                        <ListItemIcon>
                            <LabelImportant/>
                        </ListItemIcon>
                        <ListItemText primary={x.Subject}/>
                        </ListItemButton>
                    )} 
                  </List>
                </Collapse>*/}
              </List>
            );
    }

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh'>
            <MenuStrip />
            <Box sx={{ height: '100%', marginBottom: 5, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                {SideBar()}
                <Box marginLeft={isMobile ? 0 : 5} marginTop={2}>
                    {Display}
                </Box>
            </Box>
        </Box>
    )
}