export default class Services {
    Name: string;
    Description: string;
    Examples: string[];
    Frameworks: string[];
    Avalabel: boolean;

    constructor(Name: string, Description: string, Examples: string[], Frameworks: string[], Avalabel: boolean = true) {
        this.Name = Name;
        this.Description = Description;
        this.Examples = Examples;
        this.Frameworks = Frameworks;
        this.Avalabel = Avalabel;
    }
}