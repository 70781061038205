export default class MailContact {
    Name: string;
    Email: string;
    Message: string;
    ServiceName: string;
    ServiceTag: string;
    SendDate: Date;

    constructor(Name: string, Email: string, Message: string, ServiceName: string, ServiceTag: string, SendDate:Date) {
        this.Name = Name;
        this.Email = Email;
        this.Message = Message;
        this.ServiceName = ServiceName;
        this.ServiceTag = ServiceTag;
        this.SendDate = SendDate;
    }
}