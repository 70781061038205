import { useEffect, useContext, useState } from 'react';
import { useQuery } from "react-query";
import {
    Box, Button, Typography as Label, Grid2 as Grid, TextField, IconButton, InputAdornment,
    Stack, Slider, Input, Select, MenuItem, Card, Paper, Drawer, Link,
    Divider, Chip,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Collapse,
    ToggleButton,
} from "@mui/material";
import { Add, Clear, FilterList, Search as SearchIcon, ArrowDropDown, ArrowDropUp, FilterAltOff } from '@mui/icons-material';
import { AuthContext } from '../Context/AuthContext';
import MenuStrip from "../Components/MenuStrip";
import Footer from "../Components/Footer";
import Loader from '../Components/Loader';
import { GetPublicProjects } from '../Controllers/DB';
import { Colours } from '../Resources/Values/Styles';
import TagList from '../Components/TagList';
import { FilterTags } from '../Resources/Values/Data';
import GetProvider from '../Components/ProjectButton';
import Project from '../Models/ProjectModel';

export default function ProjectssPage() {
    const { isMobile } = useContext(AuthContext);
    const [PublicProject, SetPublicProject] = useState<Project[]>([]);
    const { data: ActProject, status } = useQuery("PubProj", async () => await GetPublicProjects());
    useQuery('PubProjLoad', FilterAndSearch, { enabled: !!ActProject });

    //Filter
    const [Search, SetSearch] = useState('');
    const [Tags, SetTags] = useState<string[]>([]);

    //Drawer
    const [collapseTags, setCollapseTags] = useState(true);
    const [state, setState] = useState(false);

    const getCollapseIcon = (val: boolean) => {
        if (collapseTags) return <ArrowDropUp />
        else return <ArrowDropDown />
    }

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState(open);
        };

    useEffect(() => FilterAndSearch(), [Tags]);

    const handleTagToggle = (value: string) => () => {
        const currentIndex = Tags.indexOf(value);
        const newChecked = [...Tags];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        SetTags(newChecked);
    };

    function FilterAndSearch() {
        if (ActProject == undefined || ActProject.length <= 0) return;
        Search.split(' ');
        let Proj = ActProject.filter(x => {
            if (Search == '') return true;
            return Search.split(' ').some(el => x.Name.toLowerCase().includes(el.toLowerCase()));
        });
        Proj = Proj.filter(x => {
            if (Tags.length <= 0) return true;
            return Tags.some(el => x.Tags.includes(el));
        });
        SetPublicProject(Proj);
    }

    if (status === "loading") {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === 'error') {
        return <div>Error...</div>
    }

    function MapFilter() {
        if (isMobile)
            return (
                <Stack direction={'column'}>
                    <Button sx={{ fontWeight: 'bold', alignSelf: 'start', border: '1px solid gray', borderRadius: 100, paddingY: 0, margin: 2 }}
                        startIcon={<FilterList />} onClick={toggleDrawer(true)} color='inherit'>
                        Filters
                    </Button>
                    <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}
                        sx={{ width: '75%', flexShrink: 0, [`& .MuiDrawer-paper`]: { width: '75%', boxSizing: 'border-box' } }}>
                        <Stack direction={'row'}>
                            <Label variant='h6' fontWeight={'bold'} alignSelf={'start'} marginLeft={1} marginRight={'auto'} marginY={'auto'}>Filters</Label>
                            <Button sx={{ border: 0, padding: 1, marginTop: -1 }} color='inherit'
                                onClick={() => { SetTags([]) }}>
                                <FilterAltOff />
                            </Button>
                        </Stack>
                        <Divider />
                        <Stack direction={'row'}>
                            <Label alignSelf={'start'} variant='body1' fontWeight={'bold'}
                                marginLeft={1.5} marginRight={'auto'} marginY={'auto'}>
                                Tags
                            </Label>
                            <Button sx={{ border: 0, padding: 1 }} color='inherit'
                                onClick={() => { setCollapseTags(!collapseTags); }}>
                                {getCollapseIcon(collapseTags)}
                            </Button>
                        </Stack>
                        <Collapse orientation="vertical" in={collapseTags}>
                            <List sx={{ position: 'relative', overflow: 'auto', maxHeight: 300, '& ul': { padding: 0 } }}>
                                {FilterTags.map((x, I) => {
                                    const labelId = `checkbox-list-label-${x}`;

                                    return (<ListItem key={I} disablePadding>
                                        <ListItemButton role='undefined' onClick={handleTagToggle(x)} dense>
                                            <ListItemIcon>
                                                <Checkbox edge="start"
                                                    checked={Tags.indexOf(x) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={x} />
                                        </ListItemButton>
                                    </ListItem>
                                    )
                                })}
                            </List>
                        </Collapse>
                        <Divider />
                    </Drawer>
                </Stack>
            )

        return (
            <Paper sx={{
                display: 'flex', flexDirection: 'column', padding: 1, marginLeft: 5,
                width: 300, minHeight: 500, border: '1px solid lightgray'
            }}
                elevation={2}>
                <Stack direction={'row'}>
                    <Label variant='h6' fontWeight={'bold'} alignSelf={'start'} marginRight={'auto'} marginY={'auto'}>Filters</Label>
                    <Button sx={{ border: 0, padding: 1, marginTop: -1 }} color='inherit'
                        onClick={() => { SetTags([]) }}>
                        <FilterAltOff />
                    </Button>
                </Stack>
                <Divider />
                <Stack direction={'row'}>
                    <Label alignSelf={'start'} variant='body1' fontWeight={'bold'}
                        marginLeft={1.5} marginRight={'auto'} marginY={'auto'}>
                        Tags
                    </Label>
                    <Button sx={{ border: 0, padding: 1 }} color='inherit'
                        onClick={() => { setCollapseTags(!collapseTags); }}>
                        {getCollapseIcon(collapseTags)}
                    </Button>
                </Stack>
                <Collapse orientation="vertical" in={collapseTags}>
                    <List sx={{ position: 'relative', overflow: 'auto', maxHeight: 300, '& ul': { padding: 0 }, }}>
                        {FilterTags.map((x, I) => {
                            const labelId = `checkbox-list-label-${x}`;

                            return (<ListItem key={I} disablePadding>
                                <ListItemButton role='undefined' onClick={handleTagToggle(x)} dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start"
                                            checked={Tags.indexOf(x) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={x} />
                                </ListItemButton>
                            </ListItem>
                            )
                        })}
                    </List>
                </Collapse>
                <Divider />
            </Paper>
        )
    }

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh'>
            <MenuStrip />
            <Box sx={{ backgroundColor: Colours.Secondary, padding: 5, marginX: 5, marginBottom: isMobile ? 0 : 5, color: Colours.Primary }}
                display={"flex"} flexDirection="row" justifyContent={'center'} alignItems={'center'}>
                <Label variant='h2'>Projects</Label>
            </Box>
            <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'}>
                {MapFilter()}
                <Box display={"flex"} flexDirection={'column'} width={'100%'}>
                    <TextField sx={{ backgroundColor: Colours.Primary, width: isMobile ? '90%' : '50%', alignSelf: 'center' }}
                        placeholder='Search...' value={Search} onChange={(e) => { SetSearch(e.target.value); }}
                        onKeyUp={(e) => { if (e.key == 'Enter') FilterAndSearch() }}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={() => FilterAndSearch()}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => { SetSearch(''); FilterAndSearch(); }}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }} />
                    <Grid container spacing={1} padding={isMobile ? 2 : 10}>
                        {PublicProject?.map((x, I) =>
                            <Grid size={{ xs: 6, sm: 4 }} key={I}>
                                <Box>
                                    <Card sx={{
                                        paddingX: 2, paddingY: 3,
                                        display: 'flex', flexDirection: 'column', height: 200
                                    }} onClick={() => {window.open('Projects/' + x.ID)}}
                                        variant='elevation'>
                                        <Chip sx={{ position: 'absolute', marginTop: -2.5, marginRight: -1.5, opacity: .7, fontSize: 10, alignSelf: 'end' }}
                                            variant="outlined" label={"V" + x.MK} />
                                        <Link variant="h5" color={'inherit'} underline='hover' target="_blank" href={'Projects/' + x.ID}
                                            marginTop={.5}>
                                            {x.Name}
                                        </Link>
                                        <Label sx={{ opacity: .7 }} variant="body2">
                                            {x.ShortDesc(75)}
                                        </Label>
                                        <Divider sx={{ marginTop: 1 }} />
                                        <Box sx={{ width: 'fit-content' }} marginTop={'auto'}>
                                            {GetProvider(x.Providers[0])}
                                        </Box>
                                    </Card>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            <Footer />
        </Box >
    )
}