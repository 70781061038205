import { useContext, useState } from 'react';
import { Button, Typography as Label, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from '@mui/system';
import { AuthContext } from '../Context/AuthContext';

export default function MenuStripItem(ID: string, Titel: string, Link: string, Icon: JSX.Element) {
    const { isMobile } = useContext(AuthContext);

    if (isMobile)
        return (
            <ListItemButton key={ID} color='inherit' href={Link}>
                <ListItemIcon>
                    {Icon}
                </ListItemIcon>
                <ListItemText primary={Titel} />
            </ListItemButton>
        )
    return (
        <Box key={ID}>
            <Button color='inherit' href={Link}>
                <Label variant='body1' textAlign={'center'}>
                    {Titel}
                </Label>
            </Button>
        </Box>
    )
}