import { useContext, useState } from 'react';
import { Box, Grid2 as Grid, Button, ButtonBase, Typography as Label, Link, Stack, IconButton, Drawer, Tooltip, List, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import { AuthContext } from '../Context/AuthContext';
import MenuStripItem from './MenuStripItem';
import Logo from '../Resources/Images/Logo.png';
import { Img } from '../Resources/Values/Styles';
import { AccountCircle, Build, Contacts, Dehaze, DeveloperMode, Home, Info, Login, Logout } from '@mui/icons-material';
import '../Resources/CSS/MiniLoader.css';

export default function MenuStrip() {
    const { User, UserLoadingStatus, isMobile, handleLogout } = useContext(AuthContext);

    const HeaderItems = [
        MenuStripItem('H', 'Home', '/', <Home />),
        MenuStripItem('A', 'About', '/About', <Info />),
        MenuStripItem('P', 'Projects', '/Projects', <DeveloperMode />),
        //MenuStripItem('B', 'Build', '/Build', <Build />),
        MenuStripItem('C', 'Contacts', '/Contact', <Contacts />),
    ];

    const [state, setState] = useState(false);

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState(open);
        };

    function Header() {
        if (UserLoadingStatus === 'loading') return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginLeft={'auto'} marginRight={10} marginTop={-4.25}>
                <span className="MiniLoader"></span>
            </Box>
        )
        if (!User) return LoginIcon();
        else return (
            <Box display={'flex'} flexDirection='row' justifyContent={'center'} alignItems={'center'} marginLeft={'auto'} marginRight={5}>
                {AccountIcon()}
                <Tooltip title="Logout">
                    <IconButton sx={{ marginLeft: 1 }} onClick={() => handleLogout()}><Logout /></IconButton>
                </Tooltip>
            </Box>
        );

        function LoginIcon() {
            if (isMobile) return (
                <Stack direction={'row'} display={'flex'} alignItems={'center'} marginLeft={'auto'} marginRight={2}>
                    <IconButton href="/LogIn" onClick={() => sessionStorage.setItem('URLLog', window.location.pathname)}>
                        <Login />
                    </IconButton>
                    <Button sx={{ border: '1px solid gray', borderRadius: 2, padding: 0, paddingX: 1, marginLeft: 1 }}
                        href='/SignUp' variant='text' color='inherit' onClick={() => sessionStorage.setItem('URLLog', window.location.pathname)}>
                        <Label noWrap>
                            Sign Up
                        </Label> 
                    </Button>
                </Stack>
            )
            else return (
                <Stack direction={'row'} display={'flex'} alignItems={'center'} marginLeft={'auto'} marginRight={5}>
                    <Link color={'inherit'} underline='hover' href="/LogIn" width={100}
                        onClick={() => sessionStorage.setItem('URLLog', window.location.pathname)}>
                        Log In
                    </Link>
                    <Button sx={{ border: '1px solid gray', borderRadius: 2, padding: 0, paddingX: 1, marginLeft: 2 }} fullWidth
                        href='/SignUp' variant='text' color='inherit' onClick={() => sessionStorage.setItem('URLLog', window.location.pathname)}>
                        Sign Up
                    </Button>
                </Stack>
            )
        }

        function AccountIcon() {
            if (isMobile) return (
                <Tooltip title={User?.displayName ?? User?.email ?? 'User'}>
                    <IconButton href='/Account'>
                        <AccountCircle fontSize='large' />
                    </IconButton>
                </Tooltip>
            )
            else return (
                <Link href='/Account' color={'inherit'} underline='none' noWrap>{User?.displayName ?? User?.email ?? 'User'}</Link>
            )
        }
    }

    function MapMenu() {
        const drawerWidth = 'auto';

        if (isMobile)
            return (
                <Box sx={{ position: 'absolute', left: 7, top: 15 }}>
                    <IconButton onClick={toggleDrawer(true)}>
                        <Dehaze fontSize='large' />
                    </IconButton>
                    <Drawer anchor={'left'} open={state} onClose={toggleDrawer(false)}
                        sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' } }}>
                        <Box sx={{ backgroundColor: 'black' }} display={'flex'} justifyContent={'center'}>
                            <Label fontSize={30} fontWeight={'bold'} color={'white'}>LNI.Tek</Label>
                        </Box>
                        <List sx={{ marginLeft: -2.5, marginRight: 5 }}>
                            {HeaderItems.map((el, Index) => (
                                <ListItem key={Index}>
                                    {el}
                                </ListItem>))}
                        </List>
                    </Drawer>
                </Box>
            )
        return HeaderItems.map((el, Index) => <Grid key={Index} marginY={"auto"} size={1} width={80} marginX={1}>{el}</Grid>)
    }

    return (
        <>
            <Box sx={{ height: 75, marginLeft: 3.75 }}
                display={'flex'} flexDirection={'row'} marginY={"auto"}>
                <Grid container spacing={2} width={'auto'} marginY={"auto"} columns={11}>
                    <Grid size={3}>
                        <ButtonBase sx={{ marginTop: -1, width: 160, marginLeft: isMobile ? 5 : 0 }} href='/'>
                            <Img alt="Logo" src={Logo} />
                        </ButtonBase>
                    </Grid>
                    {MapMenu()}
                </Grid>
                {Header()}
            </Box >
            {/* <Label alignSelf={'center'} variant='h6' fontWeight={'bold'} color={'red'}>This Website Is Still Under Development!</Label> */}
        </>
    )
}