import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { Box, Stack, Typography as Label, Button, } from "@mui/material";
import MenuStrip from "../Components/MenuStrip";
import Footer from "../Components/Footer";
import { Img } from "../Resources/Values/Styles";
import Logo from '../Resources/Images/Logo.png';

export default function PageNotFound() {
    const { isMobile } = useContext(AuthContext);

    return (
        <Box height={'100vh'} sx={{ backgroundColor: 'black' }}>
            <Box textAlign={'center'} paddingTop={1} marginY={'auto'}>
                <Img alt="Logo" src={Logo} sx={{maxWidth: 400}}/>

                <Label color="white" variant="h1" fontWeight={'bold'}>{"< 404 />"}</Label>
                <Label color="white" variant="h5">Page not found</Label>
                <Label color="white" maxWidth={500} marginX={isMobile ? 5 : 'auto'} marginTop={2}>
                    The page you are looking for might have been removed, had its name
                    changed or is temporarily unavailable.
                </Label>

                <Button sx={{
                    marginX:'auto', marginTop:5,
                    borderColor:'white', color:'white'
                }} variant="outlined" href="/">
                    Homepage
                </Button>
            </Box>
        </Box>
    )
}