import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography as Label, Paper, Link, IconButton } from "@mui/material";
import { Google } from "@mui/icons-material";
import { Formik } from 'formik';
import * as yup from 'yup';
import { ProviderId } from 'firebase/auth';
import { AuthContext } from '../Context/AuthContext';
import { TextBox } from "../Components/TextBox";

export default function LogInPage() {
    const [Error, SetError] = useState('');
    const { handleLogin } = useContext(AuthContext);
    const navigator = useNavigate();

    const validationSchema = yup.object().shape({
        Email: yup.string().required('Email Can Not Be Empty').email(),
        Pass: yup.string().required('Password Can Not Be Empty'),
    })

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh' justifyContent={'center'} alignItems={'center'}>
            <Paper elevation={5} sx={{ padding: 5, borderRadius: 5 }}>
                <Formik
                    initialValues={{
                        Email: '',
                        Pass: ''
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(Values) => {
                        handleLogin(Values.Email, Values.Pass, ProviderId.PASSWORD).then((MSG) => {
                            if (MSG === '') navigator(sessionStorage.getItem('URLLog') ?? '/')
                            else SetError(MSG);
                        })
                    }} >
                    {({ handleSubmit }) => {
                        return (
                            <Box display={'flex'} flexDirection='column' justifyContent='center' alignItems={'center'}>
                                <Label variant="h4" marginBottom={2}>Login</Label>
                                <Box width={300}>
                                    <Label>Email</Label>
                                    <TextBox field={{ name: 'Email' }} fullWidth variant="standard"
                                        placeholder="Type In Your Email" />
                                    <Label sx={{ marginTop: 2.5 }}>Password</Label>
                                    <TextBox field={{ name: 'Pass' }} fullWidth variant="standard"
                                        placeholder="Type In Your Password" type={'password'} />
                                    <Label fontSize={15} color='red'>{Error}</Label>
                                    <Button sx={{ marginTop: '10px', borderRadius: 10, border: '1px solid gray' }}
                                        color='inherit' onClick={() => handleSubmit()} fullWidth>
                                        Login
                                    </Button>
                                </Box>
                                <Label marginTop={2}>Or Sign In With</Label>
                                <Box flexDirection='row' justifyContent='center' alignItems={'center'}>
                                    <IconButton></IconButton>
                                    <IconButton onClick={() => {
                                        handleLogin('', '', ProviderId.GOOGLE).then((MSG) => {
                                            if (MSG === '') navigator(sessionStorage.getItem('URLLog') ?? '/')
                                            else SetError(MSG);
                                        })
                                    }}>
                                        <Google fontSize='large' />
                                    </IconButton>
                                    <IconButton></IconButton>
                                </Box>
                                <Box marginTop={5} display={'flex'} justifyContent='center' alignItems={'center'}>
                                    <Label marginRight={2} fontSize={12}>Don't Have An Account?</Label>
                                    <Link fontSize={12} href="/SignUp" color={'inherit'}>SIGN UP</Link>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Paper>
        </Box >
    )
}