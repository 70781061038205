import { styled } from '@mui/material/styles';

export const Colours = {
    Primary: 'white',
    Secondary: 'black',
    Tertiary: 'lightgray',
}

export const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});
