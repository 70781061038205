import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import Loader from "./Loader";
import { GetMDFile } from "../Controllers/Storage";
import Gfm from 'remark-gfm'
import Lint from 'remark-lint'

interface Props {
    URL: string;
}

export default function MarkDownDisplay(props: Props) {
    const { status } = useQuery(["NewsFeed", props.URL], FechMD);
    const [Loading, SetLoading] = useState(true);

    const [MD, SetMD] = useState<string>('');

    async function FechMD({ queryKey }: any) {
        const URL = queryKey[1];
        if (URL === '') return false;
        GetMDFile(URL, async (xhr) => { SetMD(await (xhr.response as Blob).text()); SetLoading(false); })
        return true;
    }

    if(!props.URL || props.URL === '') return(<></>);
    
    if (status === "loading" || Loading) {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === "error") {
        return <div>Error...</div>
    }

    return <ReactMarkdown remarkPlugins={[Gfm, Lint]}>{MD}</ReactMarkdown>
}