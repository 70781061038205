import { useContext } from "react";
import { Box, Button, Link, Stack, Typography as Label, Grid2 as Grid } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { Colours } from '../Resources/Values/Styles';
import { Contacts } from '../Resources/Values/Data';

export default function Footer(prop: { HideToTop?: boolean }) {
    const { isMobile } = useContext(AuthContext);
    let Show = prop.HideToTop ? 'hidden' : 'visible';

    const delay = (ms : number) => new Promise(res => setTimeout(res, ms));

    return (
        <Grid container marginTop={'Auto'} columns={isMobile ? 1 : 2}>
            <Grid size={2}>
                <Box display={'flex'} justifyContent="flex-end" alignItems="flex-end">
                    <Button sx={{
                        visibility: Show,
                        backgroundColor: Colours.Tertiary, color: 'inherit',
                        margin: 5, borderRadius: 0,
                    }}
                        fullWidth={isMobile}
                        onClick={async () => {
                            var I = window.scrollY;
                            for (let index = I; index >= 0; index -= 10){
                                await delay(1);
                                window.scrollTo(0, index < 0 ? 0 : index);
                            }
                        }}>
                        ↑ Back To Top
                    </Button>
                </Box>
            </Grid>
            <Grid size={1} borderTop={1} borderColor={Colours.Tertiary}>
                <Stack sx={{ padding: 2, paddingLeft: isMobile ? 0 : 10 }} color='gray' direction="column"
                    justifyContent="center" alignItems={isMobile ? 'center' : 'start'}>
                    <Label>Legal information:</Label>
                    <Link sx={{ fontWeight: 'bold' }} fontSize={12} href='/Projects/LNITEK/Policy' color='inherit' underline='hover'>
                        Privacy Policy
                    </Link>
                    <Link sx={{ fontWeight: 'bold' }} fontSize={12} href='/Projects/LNITEK/TC' color='inherit' underline='hover'>
                        Terms of Use
                    </Link>
                </Stack>
            </Grid>
            <Grid size={1} borderTop={isMobile ? 0 : 1} borderColor={Colours.Tertiary}>
                <Stack sx={{ padding: 2, paddingLeft: isMobile ? 0 : 10 }} color='gray' direction="column"
                    justifyContent="center" alignItems={isMobile ? 'center' : 'start'}>
                    <Link sx={{ fontWeight: 'bold' }} href='/Contact' color='inherit' underline='hover'>Contacts</Link>
                    {Contacts.map((x, i) => <Label paddingTop="5" key={i} fontSize={12}> {x} </Label>)}
                </Stack>
            </Grid>
        </Grid>
    )
}