import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Box, Typography as Label, Link, Card, Grid2 as Grid, Stack, Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import { Info } from "@mui/icons-material"
import { AuthContext } from "../Context/AuthContext";
import { ActivateKey, GetProject } from "../Controllers/DB";
import Loader from "./Loader";
import { Colours } from "../Resources/Values/Styles";
import Project from "../Models/ProjectModel";

interface Props {
    Values?: string[];
}
export default function MassProjectsDisplay(props: Props) {
    const [DialogOpen, setDialogOpen] = useState(false);
    const [Error, setError] = useState('Error');
    const [OpenError, setOpenError] = useState(false);
    const [OpenSuc, setOpenSuc] = useState(false);
    const [OpenLoading, setOpenLoading] = useState(false);

    const OpenDialog = () => {
        setDialogOpen(true);
    };

    const CloseDialog = (Code: string = "") => {
        if (Code === "") { setDialogOpen(false); return; }
        ActivateKey(Code).then(Activated => { if (Activated === true) setOpenSuc(true); else SetError(Activated); setOpenLoading(false); })
        setOpenLoading(true);
        setDialogOpen(false);
    };

    function SetError(msg: string) {
        setOpenError(true);
        setError(msg);
    }

    function CloseSnackbar(event: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') return;

        setOpenError(false);
        setOpenSuc(false);
    };

    function RedeemDialog() {
        const [Key, setKey] = useState("");

        return (
            <>
                <Dialog open={DialogOpen} onClose={() => CloseDialog()}>
                    <DialogTitle>Redeem A Project By Key Code</DialogTitle>
                    <DialogContent>
                        <TextField value={Key} onChange={(e) => setKey(e.target.value)}
                            autoFocus margin="dense" label="xxaac-ddufr-gropg-7wasd-pro32"
                            fullWidth variant="outlined" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => CloseDialog(Key)} color="inherit">Activate</Button>
                        <Button onClick={() => CloseDialog()} color="inherit">Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={OpenLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={OpenError} autoHideDuration={6000} onClose={CloseSnackbar}>
                    <Alert onClose={CloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {'Could Not Activate Project Key! : ' + Error}
                    </Alert>
                </Snackbar>
                <Snackbar open={OpenSuc} autoHideDuration={6000} onClose={CloseSnackbar}>
                    <Alert onClose={CloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Project Key Activated!
                    </Alert>
                </Snackbar>
            </>
        )
    }

    const { isMobile } = useContext(AuthContext);
    const { data: Proj, status } = useQuery("OwnedProj", async () => {
        if (props.Values) return (await Promise.all(props.Values.map(async X => await GetProject(X))))
            .filter(x => x != null) as Project[]; else throw '';
    });

    if (status === "loading") {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === "error") {
        return <div>Error...</div>
    }

    if ((Proj?.length ?? 0) <= 0) {
        return (
            <>
                <Grid sx={{ backgroundColor: Colours.Secondary, padding: 2, width: isMobile ? '100vw' : '100vh' }}
                    container spacing={2} marginLeft='0px'>
                    <Grid size={1}>
                        <Info sx={{ color: Colours.Primary }} />
                    </Grid>
                    <Grid size={isMobile ? 6 : 8}>
                        <Label color={Colours.Primary}>No projects Avalabel</Label>
                    </Grid>
                    <Grid size={2}>
                        <Button sx={{ borderColor: Colours.Primary, color: Colours.Primary, paddingTop: 0 }} color='inherit' variant='outlined'
                            onClick={OpenDialog}>
                            <Label noWrap>Redeem Key</Label>
                        </Button>
                    </Grid>
                </Grid>
                <RedeemDialog />
            </>
        )
    };

    return (
        <>
            <Button sx={{ borderColor: Colours.Secondary, color: Colours.Secondary, position: 'absolute', right: 10 }}
                color='inherit' variant='outlined' onClick={OpenDialog}>
                Redeem Key
            </Button>
            <Box sx={{ backgroundColor: Colours.Secondary, padding: 1, paddingX: 2, width: isMobile ? 'fit-content' : '64vw' }}
                marginBottom={'10px'}>
                <Label color={Colours.Primary}> {`Avalable projects: (${Proj?.length ?? 0})`} </Label>
            </Box>
            <Grid sx={{ height: '100%', display: 'flex', flexDirection: 'row', width: isMobile ? '100vw' : '70vw' }}
                container columns={4} columnSpacing={'10px'} rowSpacing={'10px'}>
                {Proj?.map((X, I) => {
                    if (!X) return;
                    return (
                        <Grid key={I} size={isMobile ? 3 : 2}>
                            <Card sx={{
                                cursor: 'pointer',
                                border: '1px solid gray', borderRadius: 2, padding: 3, marginX: 3,
                                width: isMobile ? '100%' : '200px'
                            }} onClick={() => window.open('Projects/' + X.ID)}>
                                <Label sx={{ position: 'absolute', marginTop: -2, opacity: .7, fontSize: 10 }} alignSelf='end'>
                                    V {X.MK}
                                </Label>
                                <Label variant="h5" color={'inherit'}>
                                    {X.Name}
                                </Label>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid >
            <RedeemDialog />
        </>
    )
}