import { Box } from '@mui/material';
import '../Resources/CSS/Loading.css';

export default function Loader() {//<span className="MainLoader" />
    return (
        <Box display={'flex'} justifyContent='center' alignItems={'center'} height={20}>
            <div className="spinner" />
        </Box>
    )
}
