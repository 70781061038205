import * as React from 'react';
import { Button, Dialog, DialogTitle, TextField, Typography as Label } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextBox } from './TextBox';

interface Prop {
    open: boolean;
    onClose: (OldPass: string, NewPass: string) => void;
}

export default function DialogBox(props: Prop) {
    const { onClose, open } = props;

    const handleClose = (OldPass: string, Pass: string) => onClose(OldPass, Pass);

    const validationSchema = yup.object().shape({
        OldPass: yup.string().required("This Field Can Not Be Empty"),
        Pass: yup.string().required("This Field Can Not Be Empty").min(6, 'Password Can Not Be Less Than 6 Characters')
            .matches(/^(\S+$)/g, 'Do Not Include Blankspaces With Your Password'),
        PassVal: yup.string().required("This Field Can Not Be Empty").min(6, 'Password Can Not Be Less Than 6 Characters')
            .matches(/^(\S+$)/g, 'Do Not Include Blankspaces With Your Password').oneOf([yup.ref('Pass'), null], "Passwords Must Match"),
    })

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle textAlign={'center'}>Change Password</DialogTitle>
            <Formik
                initialValues={{
                    OldPass: '',
                    Pass: '',
                    PassVal: '',
                }}

                validationSchema={validationSchema}

                onSubmit={async (Values) => handleClose(Values.OldPass, Values.Pass)} >

                {({ handleSubmit }) => {
                    return (
                        <>
                            <TextBox sx={{ marginX: 3.5, marginBottom: 1, width: '80%' }} type="password" label="Old Password"
                                size='small' field={{ name: 'OldPass' }} />
                            <TextBox sx={{ marginX: 3.5, marginBottom: 1, width: '80%' }} type="password" label="New Password"
                                size='small' field={{ name: 'Pass' }} />
                            <TextBox sx={{ marginX: 3.5, marginBottom: 1, width: '80%' }} type="password" label="Confirm New Password"
                                size='small' field={{ name: 'PassVal' }} />
                            <Button sx={{ marginX: 3.5, marginBottom: 5, width: '80%', borderRadius: 1.5, border: '1px solid gray' }}
                                color='inherit' onClick={() => handleSubmit()}>
                                Change Password
                            </Button>
                        </>
                    )
                }}
            </Formik>
        </Dialog >
    );
}