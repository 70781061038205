import { getStorage } from "firebase/storage";
import { FirebaseApp } from 'firebase/app';

export function GetMDFile(URL: string, Fun: ((xhr: XMLHttpRequest) => void)) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => Fun(xhr);
    xhr.open('GET', URL);
    xhr.send();
    //console.log(xhr.response);
}
