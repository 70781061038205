import { Typography as Label, IconButton, Grid, Stack } from '@mui/material';
import { Cancel } from '@mui/icons-material';

interface Prop {
    Values?: string[],
    HideDel?: boolean,
    handelDel?: ((Tag: string) => void),
}

export default function TagList(prop: Prop) {
    let Show = prop.HideDel ? 'collapse' : 'visible';
    let Width = prop.HideDel ? '0px' : 'auto';

    return (
        <Grid container>
            {prop.Values?.map((x, I) =>
                <Grid item key={I}>
                    <Stack border={'1px solid lightgray'} borderRadius={10}
                        direction={'row'} alignItems='center' margin={.5} paddingLeft={1}>
                        <Label fontSize={12.5}>{x}</Label>
                        <IconButton sx={{ visibility: Show, marginY: -1, width: Width }}
                            onClick={() => { if (prop.handelDel) prop.handelDel(x) }}>
                            <Cancel fontSize='small' />
                        </IconButton>
                    </Stack>
                </Grid>
            )}
        </Grid>
    )
}