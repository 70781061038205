export default class Provider {
    URI: string;
    Publisher: number;
    Orderby: number;

    constructor(URI: string, Publisher: number, Orderby: number) {
        this.URI = URI;
        this.Publisher = Publisher;
        this.Orderby = Orderby;
    }

    public static Gen = (Data: Provider)=>{
        return new Provider(Data.URI, Data.Publisher, Data.Orderby);
    }
}