import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { Box, Button, IconButton, InputAdornment, Typography as Label, List } from "@mui/material";
import { Formik } from 'formik';
import * as yup from 'yup';
import Loader from './Loader';
import { AuthContext } from '../Context/AuthContext';
import { ChangeCO, ChangeEmail, ChangeName, ChangePass, GetTicets, GetUser } from '../Controllers/DB';
import { TextBox } from "../Components/TextBox";
import { ProviderId } from 'firebase/auth';
import { Edit } from '@mui/icons-material';
import DialogBox from './DialogBox';
import { StringNotNullOrEmpty } from '../Controllers/Validater';
import Ticket from '../Models/TicketModel';

interface Props {
    Ticket: Ticket,
}

export default function TicketCreator(props: Props) {
    const [MSG, SetMSG] = useState('');
    const { User, handleLogin } = useContext(AuthContext);

    return (
    <>
        <Label>{props.Ticket.State}</Label>
    </>
    )
}