import Provider from "./ProviderModel";

export default class Project {
    ID: string;
    Name: string;
    Description: string;
    MK: string;
    Public: boolean;
    Policy: string;
    TC: string;
    ChangeLog: string;
    ReadMe: string;
    Providers: Provider[]
    Tags: string[];
    LastUpdated: any;

    constructor(ID: string, Name: string, Description: string, MK: string, Public: boolean, Policy: string, TC: string, 
        ChangeLog: string, ReadMe: string,  Providers: Provider[], Tags: string[], LastUpdated: any) {
        this.ID = ID;
        this.Name = Name;
        this.Description = Description;
        this.MK = MK;
        this.Policy = Policy;
        this.TC = TC;
        this.ChangeLog = ChangeLog;
        this.ReadMe = ReadMe;
        this.Public = Public;
        this.Providers = Providers;
        this.Tags = Tags;
        this.LastUpdated = LastUpdated;
    }

    public static Gen = (Data: Project)=>{
        return new Project(Data.ID, Data.Name, Data.Description, Data.MK, Data.Public, Data.Policy, Data.TC, Data.ChangeLog, Data.ReadMe,
            Data.Providers, Data.Tags, Data.LastUpdated);
    }

    ShortDesc = (length: number) => {
        if(length <= -1) return this.Description;
        if (this.Description && this.Description.length > length)
            return this.Description.substring(0, length) + "...";
        return this.Description;
    }
}