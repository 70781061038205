import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import { GetProject } from "../Controllers/DB";
import MenuStrip from "../Components/MenuStrip";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader";
import MarkDownDisplay from "../Components/MarkDownDisplay";

export default function TCDisplayPage() {
    const { id: ID } = useParams();
    const { data: TC, status } = useQuery(["Policy", ID], FechTC);

    const navigator = useNavigate();

    async function FechTC({ queryKey }: any) {
        const ID = queryKey[1];
        if (!ID) { navigator('/Projects'); return; }
        let Proj = await GetProject(ID);
        if (!Proj) { navigator('/Projects'); return; }
        return Proj.TC;
    }

    if (status === "loading") {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === 'error') {
        return <div>Error...</div>
    }
// <Footer HideToTop={true} />
    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh'>
            <MenuStrip />
            <Box padding={2}>
                <MarkDownDisplay URL={TC ?? ''} />
            </Box>
        </Box>
    )
}