import { createContext, useCallback, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider,
    getAuth, User, AuthErrorCodes, ProviderId
} from "firebase/auth";
import { ChangeName, FB, SaveNewUser } from '../Controllers/DB';
import { useQuery } from "react-query";

export const AuthContext = createContext({
    isMobile: false,
    User: {} as User | null,
    UserLoadingStatus: 'loading',
    handleSignUp: async (email: string, password: string, Name: string, CO: string, Provider: string) => { return ''; },
    handleLogin: async (email: string, password: string, Provider: string) => { return ''; },
    handleLogout: async () => { return true; },
});

export function AuthContextProvider({ children }: any) {
    const Auth = getAuth(FB);

    const { data: User, status: UserLoadingStatus } = useQuery("User", () => {
        let bLogedIN = localStorage.getItem("User");
        if (!bLogedIN) return;
        let U = Auth.currentUser;
        if (!U) throw 'Could Not Get User';
        return U;
    }, { onError: () => localStorage.removeItem("User") });
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 1000;

    const handleLogin = useCallback(async (email: string, password: string, Provider: string) => {
        let userCredential, MSG = "An Error Occurred During Login";
        try {
            switch (Provider) {
                case ProviderId.PASSWORD: userCredential = await signInWithEmailAndPassword(Auth, email, password); break;
                case ProviderId.GOOGLE: return handleSignUp(email, password, '', '', Provider);
                default: MSG = "Invalid Provider '" + Provider + "'"; break;
            }
        } catch (e) {
            switch ((e as any).code) {
                case AuthErrorCodes.INVALID_PASSWORD: MSG = "Inavlid Password"; break;
                case AuthErrorCodes.INVALID_EMAIL: MSG = "Inavlid Email"; break;
                case AuthErrorCodes.EMAIL_EXISTS: MSG = "Email Exist"; break;
                case AuthErrorCodes.ALREADY_INITIALIZED: MSG = "Account Already Exist"; break;
                case AuthErrorCodes.USER_DELETED:
                case AuthErrorCodes.CREDENTIAL_MISMATCH: MSG = "Email Or Password Mismatch"; break;
            }
            return MSG;
        }
        if (!userCredential) return MSG;
        localStorage.setItem("User", true + "");
        return '';
    }, [Auth]);

    const handleSignUp = useCallback(async (email: string, password: string, Name: string, CO: string, Provider: string) => {
        let userCredential, MSG = "An Error Occurred During Sign Up";
        try {
            switch (Provider) {
                case ProviderId.PASSWORD: userCredential = await createUserWithEmailAndPassword(Auth, email, password); break;
                case ProviderId.GOOGLE: userCredential = await signInWithPopup(Auth, new GoogleAuthProvider()); break;
            }
        } catch (e) {
            switch ((e as any).code) {
                case AuthErrorCodes.INVALID_PASSWORD: MSG = "Inavlid Password"; break;
                case AuthErrorCodes.INVALID_EMAIL: MSG = "Inavlid Email"; break;
                case AuthErrorCodes.EMAIL_EXISTS: MSG = "Email Exist"; break;
                case AuthErrorCodes.ALREADY_INITIALIZED: MSG = "Account Already Exist"; break;
                case AuthErrorCodes.USER_DELETED:
                case AuthErrorCodes.CREDENTIAL_MISMATCH: MSG = "Email Or Password Mismatch"; break;
            }
            return MSG;
        }
        if (!userCredential) return MSG;
        if (Provider === ProviderId.PASSWORD) await ChangeName(Name); else CO = "";
        localStorage.setItem("User", true + "");
        await SaveNewUser(CO);
        return '';
    }, [Auth, FB]);

    const handleLogout = useCallback(async () => {
        await Auth.signOut();
        localStorage.removeItem("User");
        window.location.reload();
        return true;
    }, []);

    return (
        <AuthContext.Provider value={{ isMobile, User: (User ?? null), UserLoadingStatus, handleLogin, handleSignUp, handleLogout }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;