import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography as Label, Paper, Link, IconButton } from "@mui/material";
import { Google } from "@mui/icons-material";
import { Formik } from 'formik';
import * as yup from 'yup';
import { ProviderId } from 'firebase/auth';
import { AuthContext } from '../Context/AuthContext';
import { TextBox } from "../Components/TextBox";

export default function SignUpPage() {
    const [Error, SetError] = useState('');
    const { handleSignUp } = useContext(AuthContext);
    const navigator = useNavigate();

    const validationSchema = yup.object().shape({
        Email: yup.string().required('Email Can Not Be Empty').email(),
        Name: yup.string().required("This Field Can Not Be Empty"),
        CO: yup.string().optional(),
        Pass: yup.string().required("This Field Can Not Be Empty").min(6, 'Password Can Not Be Less Than 6 Characters')
            .matches(/^(\S+$)/g, 'Do Not Include Blankspaces With Your Password'),
        PassVal: yup.string().required("This Field Can Not Be Empty").min(6, 'Password Can Not Be Less Than 6 Characters')
            .matches(/^(\S+$)/g, 'Do Not Include Blankspaces With Your Password').oneOf([yup.ref('Pass'), null], "Passwords Must Match"),
    })

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh' justifyContent={'center'} alignItems={'center'}>
            <Paper elevation={5} sx={{ padding: 5, borderRadius: 5 }}>
                <Formik
                    initialValues={{
                        Email: '',
                        Name: '',
                        CO: '',
                        Pass: '',
                        PassVal: '',
                    }}

                    validationSchema={validationSchema}

                    onSubmit={async (Values) => {
                        let MSG = await handleSignUp(Values.Email, Values.Pass, Values.Name, Values.CO, ProviderId.PASSWORD);
                        if (MSG === '') navigator(sessionStorage.getItem('URLLog') ?? '/')
                        else SetError(MSG);
                    }} >
                    {({ handleSubmit }) => {
                        return (
                            <Box display={'flex'} flexDirection='column' justifyContent='center' alignItems={'center'}>
                                <Label variant="h4" marginBottom={2}>Sign Up</Label>
                                <Box width={300}>
                                    <TextBox sx={{ marginBottom: '15px' }} fullWidth
                                        size='small' variant='outlined'
                                        label="Email" field={{ name: 'Email' }} />
                                    <TextBox sx={{ marginBottom: '15px' }} fullWidth variant='outlined'
                                        size='small' label="Full Name" field={{ name: 'Name' }} />
                                    <TextBox sx={{ marginBottom: '15px' }} fullWidth variant='outlined'
                                        size='small' label="Company" field={{ name: 'CO' }} />
                                    <TextBox sx={{ marginBottom: '15px' }} fullWidth
                                        size='small' variant='outlined' type="password"
                                        label="Password" field={{ name: 'Pass' }} />
                                    <TextBox sx={{ marginBottom: '15px' }} fullWidth
                                        size='small' variant='outlined' type="password"
                                        label="Confirm Password" field={{ name: 'PassVal' }} />
                                    <Label fontSize={15} color='red'>{Error}</Label>
                                    <Button sx={{ marginTop: '10px', borderRadius: 100 }} color='inherit'
                                        variant='contained' fullWidth onClick={() => handleSubmit()}>
                                        Sign Up
                                    </Button>
                                </Box>
                                <Label marginTop={2}>Or Sign Up With</Label>
                                <Box flexDirection='row' justifyContent='center' alignItems={'center'}>
                                    <IconButton></IconButton>
                                    <IconButton onClick={() => {
                                        handleSignUp('', '', '', '', ProviderId.GOOGLE).then((MSG) => {
                                            if (MSG === '') navigator(sessionStorage.getItem('URLLog') ?? '/')
                                            else SetError(MSG);
                                        })
                                    }}>
                                        <Google fontSize='large' />
                                    </IconButton>
                                    <IconButton></IconButton>
                                </Box>
                                <Box marginTop={5} display={'flex'} justifyContent='center' alignItems={'center'}>
                                    <Label marginRight={2} fontSize={12}>Already Have An Account?</Label>
                                    <Link fontSize={12} href="/Login" color={'inherit'}>LOGIN</Link>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Paper>
        </Box>
    )
}