import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, Link, Paper, Stack, Typography as Label, } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { GetProject } from "../Controllers/DB";
import MenuStrip from "../Components/MenuStrip";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader";
import TagList from "../Components/TagList";
import GetProvider from "../Components/ProjectButton";
import MarkDownDisplay from "../Components/MarkDownDisplay";

export default function ProjectsDisplayPage() {
    const { isMobile } = useContext(AuthContext);
    const { id: ID } = useParams();
    const { data: Project, status } = useQuery(["Projects", ID], FechProjects);

    const navigator = useNavigate();

    async function FechProjects({ queryKey }: any) {
        const ID = queryKey[1];
        if (!ID) { navigator('/Projects'); return; }
        let Proj = await GetProject(ID);
        if (!Proj) { navigator('/Projects'); return; }
        return Proj;
    }

    function GetDesc(){
        if(Project?.ReadMe)
            return <MarkDownDisplay URL={Project?.ReadMe ?? ""}/>
        return Project?.Description.split("\r").map((i,key) =>{
            return <Label sx={{ opacity: .7, fontSize: 17.5, marginLeft: 5 }}>{i}</Label>
        });
    }

    if (status === "loading") {
        return <Box position={'fixed'} left='50%' top={'50%'}> <Loader /></Box>
    }

    if (status === 'error') {
        return <div>Error...</div>
    }

    return (
        <Box display={'flex'} flexDirection={'column'} height='100vh'>
            <MenuStrip />
            <Paper sx={{ marginX: 5, marginBottom:2.5, paddingBottom: 2, }} elevation={2}>
                <Stack direction={isMobile ? 'column' : 'row'} display={'flex'} alignItems={isMobile ? 'start' : 'center'}>
                    <Label variant="h4" margin={2} marginBottom={0}>{Project?.Name}</Label>
                    <Box sx={{ marginLeft: 2, paddingX: 2, width: 'fit-content' }}>
                        <Stack direction={isMobile ? 'column' : 'row'} spacing={'10px'}>
                            {Project?.Providers?.map((el,I) => GetProvider(el, I))}
                        </Stack>
                    </Box>
                </Stack>
                <Box marginLeft={2} marginBottom={2} marginTop={isMobile ? 2 : 0}>
                    <TagList Values={Project?.Tags} HideDel={true} />
                </Box>
                <Stack marginLeft={2}>
                    <Link sx={{ opacity: .7, fontSize: 12 }} display={Project?.Policy === '' ? 'none' : 'block'}
                        color='inherit' underline="hover" href={`/Projects/${Project?.ID ?? ''}/Policy`}>
                        Privacy Policy
                    </Link>
                    <Link sx={{ opacity: .7, fontSize: 12 }} display={Project?.TC === '' ? 'none' : 'block'}
                        color='inherit' underline="hover" href={`/Projects/${Project?.ID ?? ''}/TC`}>
                        Terms And Conditions
                    </Link>
                </Stack>
            </Paper>
            <Paper sx={{ marginX: 5, padding:2}} elevation={2}>
                <Label variant="h5">Discription:</Label>
                <Box>
                    {GetDesc()}
                </Box>
            </Paper>
            <Footer HideToTop={true} />
        </Box>
    )
}